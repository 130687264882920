var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-12 col-md-8 col-12 mt-sm-0 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.will-desc")) + " ")]), _c('form', {
    staticClass: "login-form mt-4",
    on: {
      "submit": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onSubmit($event);
      }
    }
  }, [_c('div', {
    staticClass: "row mt-5"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("partner-name")))]), _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.organization ? _vm.organization.name : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "branch_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('p', {
    staticClass: "input-form-static"
  }, [_vm._v(" " + _vm._s(_vm.branch ? _vm.branch.branch_name : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "agent"
    }
  }, [_vm._v(_vm._s(_vm.$t("agent")))]), _c('p', {
    staticClass: "input-form-static"
  }, [_vm._v(" " + _vm._s(_vm.agent ? _vm.agent.user.name : "-") + " ")])])]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "wasiat_type"
    }
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.type")))]), _vm.wasiatType != null ? _c('div', [_vm.wasiatType == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("islamic-will")) + " ")]) : _vm._e(), _vm.wasiatType == 2 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("conventional-will")) + " ")]) : _vm._e()]) : _vm._e(), _vm.wasiatType == null && _vm.userRole == 'Staff' ? _c('div', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.wasiatType,
      expression: "wasiatType"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "group",
      "id": "group"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.wasiatType = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1",
      "selected": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("islamic-will")) + " ")]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("conventional-will")))])])]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "price"
    }
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.price")))]), _c('p', {
    staticClass: "input-form-static"
  }, [_vm._v(" RM " + _vm._s(_vm.wasiat.price.toFixed(2)) + " ")])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "promo_code"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("promo-code")))]), _vm.wasiat.promo_code ? _c('p', {
    staticClass: "input-form-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.promo_code.code) + " ")]) : _vm._e()])])]), _c('hr'), _vm.organization.is_bmmb ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "referrer_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("referral-no")))]), _c('p', {
    staticClass: "input-form-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat ? _vm.wasiat.referrer_number : "-") + " ")])])])]) : _vm._e()])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }