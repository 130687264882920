var render = function () {
  var _vm$wasiat, _vm$wasiat2, _vm$wasiat3, _vm$wasiat4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.will-desc")) + " ")]), ['Staff'].includes(_vm.userRole) ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.selectedOrganizationId.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("partner-name")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm.organizations ? _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.selectedOrganizationId.$model,
      expression: "$v.selectedOrganizationId.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.selectedOrganizationId, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.onOrganizationChange]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.organization")) + " --")]), _vm._l(Object.entries(this.organizations), function (type, index) {
    return _c('optgroup', {
      key: index,
      attrs: {
        "label": type[0]
      }
    }, _vm._l(type[1], function (organization, index2) {
      return _c('option', {
        key: index2,
        domProps: {
          "value": organization.id
        }
      }, [_vm._v(" " + _vm._s(organization.name) + " ")]);
    }), 0);
  })], 2) : _vm._e(), _vm.$v.selectedOrganizationId.$error && !_vm.$v.selectedOrganizationId.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("org-name")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.selectedBranchId.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "branch_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.selectedBranchId.$model,
      expression: "$v.selectedBranchId.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.selectedBranchId, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.selectBranch($event);
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.branch")) + " --")]), _vm._l(_vm.branches, function (branch, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": branch.id
      }
    }, [_vm._v(" " + _vm._s(branch.branch_name) + " ")]);
  })], 2), _vm.$v.selectedBranchId.$error && !_vm.$v.selectedBranchId.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 offset-md-6 form-group",
    class: {
      'has-error': _vm.$v.selectedAgentId.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "branch_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("agent")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.selectedAgentId.$model,
      expression: "$v.selectedAgentId.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.selectedAgentId, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.agent")) + " --")]), _vm._l(_vm.userAccesses, function (access, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": access.user_id
      }
    }, [_vm._v(_vm._s(access.name) + " (" + _vm._s(access.ic_number) + ")")]);
  })], 2), _vm.$v.selectedAgentId.$error && !_vm.$v.selectedAgentId.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("agent")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()])]) : _vm._e(), ['Agent'].includes(_vm.userRole) ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("partner-name")))]), _c('p', {
    staticClass: "input-form-static"
  }, [_vm._v(" " + _vm._s(_vm.getorgnamebyindex) + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "branch_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('p', {
    staticClass: "input-form-static"
  }, [_vm._v(" " + _vm._s(_vm.userAccess.accessible.branch_name) + " ")])]), _c('div', {
    staticClass: "col-md-6 offset-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "f": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("agent")))]), _vm._v(" "), _c('br'), _c('p', {
    staticClass: "input-form-static"
  }, [_vm._v(" " + _vm._s(_vm.getagentnamebyindex) + " ")])])]) : _vm._e(), ['Super Admin'].includes(_vm.userRole) ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("partner-name")))]), _c('p', {
    staticClass: "input-form-static"
  }, [_vm._v(" " + _vm._s(_vm.getorgnamebyindex) + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.selectedBranchId.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "branch_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.selectedBranchId.$model,
      expression: "$v.selectedBranchId.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.selectedBranchId, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.selectBranch($event);
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.branch")) + " --")]), _vm._l(_vm.branches, function (branch, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": branch.id
      }
    }, [_vm._v(" " + _vm._s(branch.branch_name) + " ")]);
  })], 2), _vm.$v.selectedBranchId.$error && !_vm.$v.selectedBranchId.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 offset-md-6 form-group",
    class: {
      'has-error': _vm.$v.selectedAgentId.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "branch_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("agent")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.selectedAgentId.$model,
      expression: "$v.selectedAgentId.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.selectedAgentId, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.agent")) + " --")]), _vm._l(_vm.userAccesses, function (access, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": access.user_id
      }
    }, [_vm._v(_vm._s(access.name) + " (" + _vm._s(access.ic_number) + ")")]);
  })], 2), _vm.$v.selectedAgentId.$error && !_vm.$v.selectedAgentId.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("agent")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()])]) : _vm._e(), ['Admin'].includes(_vm.userRole) ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("partner-name")))]), _c('p', {
    staticClass: "input-form-static"
  }, [_vm._v(" " + _vm._s(_vm.getorgnamebyindex) + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "branch_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('p', {
    staticClass: "input-form-static"
  }, [_vm._v(" " + _vm._s(_vm.userAccess.accessible.branch_name) + " ")])]), _c('div', {
    staticClass: "col-md-6 offset-md-6 form-group",
    class: {
      'has-error': _vm.$v.selectedAgentId.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "branch_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("agent")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.selectedAgentId.$model,
      expression: "$v.selectedAgentId.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.selectedAgentId, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.agent")) + " --")]), _vm._l(_vm.userAccesses, function (access, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": access.user_id
      }
    }, [_vm._v(_vm._s(access.name) + " (" + _vm._s(access.ic_number) + ")")]);
  })], 2), _vm.$v.selectedAgentId.$error && !_vm.$v.selectedAgentId.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("agent")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()])]) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.wasiatType.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "wasiat_type"
    }
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.type")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.wasiatType.$model,
      expression: "$v.wasiatType.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "group",
      "id": "group"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.wasiatType, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.will")) + " --")]), _c('option', {
    attrs: {
      "value": "1",
      "selected": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("islamic-will")) + " ")]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("conventional-will")))])]), _vm.$v.wasiatType.$error && !_vm.$v.wasiatType.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.price")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.pricing.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "price"
    }
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.price")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.pricing.$model,
      expression: "$v.pricing.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "",
      "disabled": (_vm$wasiat = _vm.wasiat) === null || _vm$wasiat === void 0 ? void 0 : _vm$wasiat.payment_received_at
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.pricing, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.price")) + " --")]), _vm._l(_vm.pricings, function (pricing, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": pricing.id
      }
    }, [_vm._v(" RM " + _vm._s(pricing.price.toFixed(2)) + " "), pricing.description ? _c('span', [_vm._v(" (" + _vm._s(pricing.description) + ") ")]) : _vm._e(), !pricing.description ? _c('span') : _vm._e()]);
  })], 2), _vm.$v.pricing.$error && !_vm.$v.pricing.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.price")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "promo_code"
    }
  }, [_vm._v(_vm._s(_vm.$t("promo-code")))]), _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.promo_code,
      expression: "promo_code"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": (_vm$wasiat2 = _vm.wasiat) === null || _vm$wasiat2 === void 0 ? void 0 : _vm$wasiat2.promo_code,
      "id": "promo_code",
      "placeholder": "Promo Code",
      "aria-label": "Promo Code"
    },
    domProps: {
      "value": _vm.promo_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.promo_code = $event.target.value;
      }
    }
  }), !((_vm$wasiat3 = _vm.wasiat) !== null && _vm$wasiat3 !== void 0 && _vm$wasiat3.promo_code) ? _c('div', {
    staticClass: "input-group-append"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm",
    attrs: {
      "id": "basic-addon2"
    },
    on: {
      "click": _vm.checkPromoCode
    }
  }, [_vm._v(_vm._s(_vm.$t("check")))])]) : _vm._e()]), _vm.promoCodeStatus == 404 ? _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.$t("promo-code-invalid")))]) : _vm._e(), _vm.promoCodeStatus == 200 ? _c('span', [!_vm.promoCode.redeemed_at ? _c('small', {
    staticClass: "text-success"
  }, [_vm._v(_vm._s(_vm.$t("promo-message-details", {
    amount: _vm.promoCode.amount_discount,
    organizationName: _vm.promoCode.organization.name
  })))]) : _vm._e(), _vm.promoCode.redeemed_at ? _c('small', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.$t("promo-code-invalid")))]) : _vm._e()]) : _vm._e(), (_vm$wasiat4 = _vm.wasiat) !== null && _vm$wasiat4 !== void 0 && _vm$wasiat4.promo_code ? _c('small', {
    staticClass: "text-success"
  }, [_vm._v(_vm._s(_vm.$t("promo-message-details", {
    amount: _vm.wasiat.promo_code.amount_discount,
    organizationName: _vm.wasiat.organization.name
  })))]) : _vm._e()])])]), _c('hr'), ['Agent', 'Super Admin'].includes(_vm.userRole) && _vm.getorgnamebyindex == 'BANK MUAMALAT MALAYSIA BERHAD' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "wasiat_type"
    }
  }, [_vm._v(_vm._s(_vm.$t("referral-no")))]), this.wasiat.referrer_number == null ? _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.referrer_number,
      expression: "referrer_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('referral-no'),
      "id": "referrer_number"
    },
    domProps: {
      "value": _vm.referrer_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.referrer_number = $event.target.value;
      }
    }
  })]) : _vm._e(), this.wasiat.referrer_number != null ? _c('div', [_vm._v(" " + _vm._s(this.wasiat.referrer_number) + " ")]) : _vm._e()])])]) : _vm._e(), ['Staff'].includes(_vm.userRole) && _vm.selectedOrganizationId == 34 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "wasiat_type"
    }
  }, [_vm._v(_vm._s(_vm.$t("referral-no")))]), this.wasiat.referrer_number == null ? _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.referrer_number,
      expression: "referrer_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('referral-no'),
      "id": "referrer_number"
    },
    domProps: {
      "value": _vm.referrer_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.referrer_number = $event.target.value;
      }
    }
  })]) : _vm._e(), this.wasiat.referrer_number != null ? _c('div', [_vm._v(" " + _vm._s(this.wasiat.referrer_number) + " ")]) : _vm._e()])])]) : _vm._e()])]), _c('div', {
    staticClass: "text-right mt-3"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }